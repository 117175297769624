export const BROADCAST_EVENTS = {
  company_update: "company_update",
  member_update: "member_update",
  member_role_update: "member_role_update",
  member_invite: "member_invite",
  member_delete: "member_delete",
  member_invite_cancelled: "member_invite_cancelled",
  credit_update: "credit_update",
  job_update: "job_update",
  job_create: "job_create",
  job_delete: "job_delete",
  new_message: "new_message",
  message_update: "message_update",
  chat_update: "chat_update",
  chat_delete: "chat_delete",
  application_create: "application_create",
  application_create_many: "application_create_many",
  application_update: "application_update",
  // application_delete: "application_delete",
  payment_create: "payment_create",
};
