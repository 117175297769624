import { useEmployerStore } from "@/store/employer";
import { useFormStore } from "@/store/form";
import { useCompanyStore } from "@/store/company";

export default defineNuxtRouteMiddleware(async (to, _) => {
  const user = useSupabaseUser();
  const { $posthog } = useNuxtApp();
  const supabase = useSupabaseClient();
  const employerStore = useEmployerStore();
  const formStore = useFormStore();
  const companyStore = useCompanyStore();
  const { company } = storeToRefs(companyStore);
  const { employer } = storeToRefs(employerStore);

  const invited = user.value?.user_metadata.invited;
  const new_owner = user.value?.user_metadata.new_owner;
  const is_employer = user.value?.user_metadata.is_employer;

  if (!user.value) {
    await supabase.removeAllChannels();
    return;
  }

  if (!is_employer) {
    await supabase.auth.signOut();
    showNotifyError(ERROR_MESSAGES.has_app_account);
    return navigateTo(ROUTES.login);
  }

  await formStore.fetchFormOptions();

  if (!employer.value) await employerStore.getEmployer(user.value.id);

  if (employer.value!.invite_pending || new_owner) {
    const targetPath = invited ? ROUTES.createCompanyMember : ROUTES.createCompanyOwner;
    if (to.path !== targetPath) return navigateTo(targetPath);
  }

  if (!company.value) await companyStore.getCompany(employer.value!.company_id);

  subscribeToListeners(company.value!.id, employer.value!.id);
  setPermissions(employer.value!);

  const { $clientPosthog } = useNuxtApp();

  if ($posthog)
    //@ts-ignore
    $clientPosthog?.identify(employer.value!.id, {
      firstName: employer.value!.first_name,
      lastName: employer.value!.last_name,
      email: employer.value!.email,
    });
});
